import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { withOidcSecure } from '@axa-fr/react-oidc-context';

const AuthRoute: React.FC<RouteProps> = props => {
	// #region State
	const { oidcUser } = useReactOidc();
	// #endregion

	// #region DOM Node
	if (!oidcUser) {
		return <Route {...props} component={withOidcSecure(props.component)} />;
	} else {
		return <Route {...props} />;
	}
	// #endregion
};

export default AuthRoute;
