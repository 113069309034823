import axios from 'axios';

import customToast from '../components/shared/customToast';
import { baseURL } from '../constants/config';
import { Error } from '../constants/models';
import { User } from '../components/auth/Interfaces';
import { Action } from '../state/globalStore';
import {
  setGlobalErrorAction,
  setItemErrorAction
} from '../state/globalActions';

export const apiClient = (user: User) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: `${user.token_type} ${user.access_token}`,
      'Content-Type': 'application/json',
      'Id-Token': user.id_token
    },
    responseType: 'json'
  });
};

export const clearLocalStorage = (key: string) => {
  window.localStorage.removeItem(key);
};

export const handleErrorResponse = (
  error: Error,
  dispatch: (action: Action) => void,
  type: string | undefined
) => {
  let errorResponse: string | undefined = '';

  if (error && error.response && error.response.data) {
    // I expect the API to handle error responses in valid format
    console.log(error.response.data);
    if (error.response.data.displayMessage) {
      errorResponse = error.response.data.displayMessage;
    } else {
      errorResponse = error.response.data.Message;
    }
    // JSON stringify if you need the json and use it later
  } else if (error && error.request) {
    console.log(error.request);
    // TO Handle the default error response for Network failure or 404 etc.,
    if (error.request.message || error.request.statusText) {
      errorResponse = error.request.message || error.request.statusText;
    } else {
      errorResponse = 'A Network or other Unknown Error occurred';
    }
  } else {
    console.log(error.message);
    errorResponse = error.message;
  }

  switch (type) {
    case 'item':
      dispatch(setItemErrorAction(errorResponse));
      break;
    default:
      customToast.error(errorResponse, {
        toastId: 'error-toast',
        onClose: () => {
          dispatch(setGlobalErrorAction(''));
        }
      });

      dispatch(setGlobalErrorAction(errorResponse));
      break;
  }
};
