import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { clearLocalStorage } from '../../api/api';
import '../../styles/Navigation.scss';

const Navigation: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <nav
      role="navigation"
      aria-label="Mobile Nav"
      className="neutron-mobile-nav-dark__top-bar navigation"
    >
      <div className="container-fluid">
        <div className="row navigation__row">
          <div className="pl-0 col-3">
            {location.pathname.includes('apps') && (
              <button
                className="neutron-button pl-0 pull-left"
                onClick={() => {
                  history.replace('/');
                  clearLocalStorage('AppDetails');
                }}
              >
                <i className="navigation__icon material-icons neutron-button__icon neutron-button__icon--left">
                  chevron_left
                </i>
                <span className="neutron-mobile-nav__tag">Back</span>
              </button>
            )}
          </div>
          <Link
            to="/"
            className="col-6 neutron-text--align-center navigation__title pr-0 pl-0"
          >
            <p
              aria-label="Title -- Mobile Catalog"
              className="neutron-text--product"
            >
              Mobile Catalog
            </p>
            <span className="sr-only">(Link to Home Page)</span>
          </Link>
          <div className="col-3"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
