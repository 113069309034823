// #region Imports
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';
// #endregion

// #region Info
/* https://tools.ietf.org/html/rfc6749#section-3.1.2.5
 * The redirection request to the client's endpoint results in
 * an HTML document response, processed by the application.  If the HTML
 * response is served directly as the result of the redirection request,
 * any script included in the HTML document will execute with full
 * access to the redirection URI and the credentials it contains.
 *
 * The client SHOULD NOT include any third-party scripts (e.g., third-
 * party analytics, social plug-ins, ad networks) in the redirection
 * endpoint response. Instead, it SHOULD extract the credentials froms
 * the URI and redirect the application again to another endpoint without
 * exposing the credentials (in the URI or elsewhere). If third-party
 * scripts are included, the client MUST ensure that its own scripts
 * (used to extract and remove the credentials from the URI) will
 * execute first.
 */
// #endregion

// #region Component
export default function RedirectPage(): JSX.Element {
	// #region State
	const { oidcUser } = useReactOidc();
	// #endregion

	// #region DOM Node
	return <Redirect to={oidcUser.state.url} />;
	// #endregion
}
// #endregion
