import React from 'react';

const Loading: React.FC = () => {
	return (
		<>
			<div
				className="container center-content__col--top-spacing neutron-text--align-center"
				role="status"
			>
				<span className="sr-only">Loading...</span>
				<svg
					className="neutron-feedback--spinner"
					viewBox="0 0 66 66"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle
						className="neutron-feedback--spinner__fill"
						fill="none"
						strokeWidth="2"
						strokeLinecap="round"
						cx="33"
						cy="33"
						r="30"
					/>
				</svg>
			</div>
		</>
	);
};

export default Loading;
