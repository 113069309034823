import React from 'react';
import { toast } from 'react-toastify';

import '../../styles/CustomToast.scss';

const customToast = {
  success(msg: string | undefined, options = {}) {
    const Success = () => (
      <i className="material-icons neutron-snackbar__feedback-icon mr-10">
        check_circle
      </i>
    );

    return toast.success(
      <div className="neu-toast__content">
        <Success />
        <span>{msg}</span>
      </div>,
      {
        ...options,
        position: 'top-right',
        className: 'neu-toast--success Toastify__close-button--success'
      }
    );
  },
  error(msg: string | undefined, options = {}) {
    const Error = () => (
      <i className="material-icons neutron-snackbar__feedback-icon mr-10">
        error
      </i>
    );

    return toast.error(
      <div className="neu-toast__content">
        <Error />
        <span>{msg}</span>
      </div>,
      {
        ...options,
        position: 'top-right',
        className: 'neu-toast--error Toastify__close-button--error'
      }
    );
  },
  warn(msg: string | undefined, options = {}) {
    const Warn = () => (
      <i className="material-icons neutron-snackbar__feedback-icon mr-10">
        warning
      </i>
    );

    return toast.warn(
      <div className="neu-toast__content">
        <Warn />
        <span>{msg}</span>
      </div>,
      {
        ...options,
        position: 'top-right',
        className: 'neu-toast--warn Toastify__close-button--warning'
      }
    );
  }
};

export default customToast;
