import React from 'react';
import ErrNotFound from '../main/ErrNotFound';

const SessionLost: React.FC = () => {
  return (
    <>
      <ErrNotFound
        statusCode={500}
        title={'Session Lost'}
        message={'To reinstate a session, relocate back to the home page.'}
      />
    </>
  );
};

export default SessionLost;
