/* eslint-disable */ 
"use strict";

var _this = void 0;

/* ==========================================================================
   Neutron Button Groups
   ========================================================================== */

/**
 *
 * IMPORTANT:
 *
 *
 * TODO:
 */

/* ==========================================================================
   Global letiables
   ========================================================================== */

/*
Toggle Buttons
*/

/* defines and assigns all dropdown tabs */
var buttonToggles = document.querySelectorAll('.neutron-button-toggles .neutron-button-toggles__button');
/* iterates over all buttons in node list */

Object.keys(buttonToggles).map(function (b) {
  /* adds event listener to buttons */
  buttonToggles[b].addEventListener('click', handleButtonToggle);
});
/* removes button class */

function buttonOff() {
  Object.keys(buttonToggles).map(function (t) {
    buttonToggles[t].classList.remove('neutron-button-toggles__button-active');
  });
}
/* toggles button class */


function handleButtonToggle(e) {
  // clears active button
  buttonOff();
  e.currentTarget.classList.toggle('neutron-button-toggles__button-active');
}
/* ================================================================================
   Checkbox JS
   ================================================================================ */

/* Component Variables */


var neutronCheckboxes = document.querySelectorAll('.neutron-checkbox__box');
/* EventListeners */

neutronCheckboxes.forEach(function (c) {
  c.addEventListener('click', handleCheckboxClick);
  c.addEventListener('keypress', handleCheckboxKeyPress);
});
/* Handle Functions */

function handleCheckboxClick(e) {
  if (e.target.nodeName === 'SPAN' && e.target.className !== '') {
    toggleCheckbox(e);
  }
}

function handleCheckboxKeyPress(e) {
  var spacebar = e.keyCode === 32;

  if (spacebar) {
    e.preventDefault();
    toggleCheckbox(e);
  }
}
/* Helper Functions */


function toggleCheckbox(e) {
  var isChecked = e.target.classList.contains('neutron-checkbox__box-is-checked');
  e.target.classList.toggle('neutron-checkbox__box-is-checked', !isChecked);
  e.target.setAttribute('aria-checked', !isChecked);
  e.target.setAttribute('aria-label', "checked-".concat(!isChecked));
}
/* ==========================================================================
   Neutron Chip
   ========================================================================== */

/**
 *
 * IMPORTANT:
 *
 *
 * TODO:
 */

/* ==========================================================================
   Global Variables
   ========================================================================== */


var chips = document.querySelectorAll('.neutron-chip--interact');
Object.keys(chips).map(function (c) {
  chips[c].addEventListener('click', handleChipClick);
});

function handleChipClick(e) {
  // let children = e.currentTarget.children;
  // let chipActionButton = returnChildWithClass(children, 'neutron-chip__action');
  // let isHidden = chipActionButton.getAttribute(('aria-hidden')) === 'true';

  /* if the target is the action button it removes chip */
  if (e.target.className.includes('neutron-chip__action')) {
    this.parentNode.removeChild(this);
  }
} // function returnChildWithClass (elements, desiredClass) {
//   for (const i in elements) {
//     if (elements[i].classList.contains(desiredClass)) {
//       return elements[i];
//     }
//   }
// }


function showCode() {
  var codeBox = document.getElementById('componentCode');

  if (codeBox.style.display === 'block') {
    codeBox.style.display = 'none';
  } else {
    codeBox.style.display = 'block';
  }
}

function CopyToClipboard(containerid) {
  if (document.selection) {
    var range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select().createTextRange();
    document.execCommand('copy');
  } else if (window.getSelection) {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().addRange(range);
    document.execCommand('copy');
    setTimeout("alert('Code snippet has been copied!')", 5);
  }
}
/* ================================================================================
  Dropdown
  ================================================================================ */

/*
 * When the user clicks on the button,
 * toggle between hiding and showing the dropdown content
 */

/* defines and assigns all dropdown tabs */


var dropdownTabs = document.querySelectorAll('.neutron-dropdown--tab .neutron-tablist__tab');
/* iterates over all dropdown tabs in node list */

Object.keys(dropdownTabs).map(function (t) {
  /* adds event listener to each tab that has a dropdown */
  dropdownTabs[t].addEventListener('click', handleDropdownClick);
});
/* defines function that toggles visibility of dropdown content */

function handleDropdownClick(e) {
  // clears all active dropdowns
  removeActiveDropdowns();
  e.currentTarget.nextElementSibling.classList.toggle('neutron-dropdown__is-active');
}
/* removes visibility class of dropdown content
 * when user clicks off of the menu
 */


document.addEventListener('mouseup', function (e) {
  var isTab = e.target.classList.contains('.neutron-tablist__tab');

  if (!isTab) {
    removeActiveDropdowns();
  }
});

function removeActiveDropdowns() {
  var dropdownContent = document.querySelectorAll('.neutron-dropdown--tab .neutron-dropdown__content');
  Object.keys(dropdownContent).map(function (c) {
    dropdownContent[c].classList.remove('neutron-dropdown__is-active');
  });
} // eslint-disable-next-line prettier/prettier


var uploaderButton = document.querySelector('.neutron-file-uploader .neutron-file-uploader--button .neutron-file-uploader--upload');

if (document.body.contains(uploaderButton)) {
  uploaderButton.onchange = function () {
    document.querySelector('.neutron-input__file-uploader').value = this.value;
  };
}
/* ================================================================================
  Password Field Visibility Toggle
   ================================================================================ */
// function togglePasswordVisibility (event) {
//   // capture the button that was clicked
//   let toggleButton = event.target;
//   // get the whole text field
//   let textField = toggleButton.closest('.neutron-js-text-field--password');
//   // get the button visibility label
//   let visibilityLabel = textField.querySelector('.neutron-input__visibility-label');
//   // get the button visibility icon
//   let visibilityIcon = textField.querySelector('.neutron-input__visibility-icon');
//   // get the input from this text field
//   let passwordInput = textField.querySelector('.neutron-input__input');
//
//   // toggle type from password to text
//   if (passwordInput.type === 'password') {
//     passwordInput.type = 'text';
//     visibilityLabel.innerHTML = 'Hide';
//     visibilityLabel.classList.add('neutron-text--denim');
//     visibilityIcon.innerHTML = 'visibility'; // this is based on how Material Icons work
//     visibilityIcon.classList.add('neutron-text--denim');
//   } else {
//     passwordInput.type = 'password';
//     visibilityLabel.innerHTML = 'Show';
//     visibilityLabel.classList.remove('neutron-text--denim');
//     visibilityIcon.innerHTML = 'visibility_off'; // this is based on how Material Icons work
//     visibilityIcon.classList.remove('neutron-text--denim');
//   }
// }

/* ==========================================================================
   Neutron Modals
   ========================================================================== */

/**
 * Basic States:
 *
 * IMPORTANT:
 *
 * TODO:
 */

/* ==========================================================================
    

    SMALL MODAL


========================================================================== */

/* ============================== 
    Global Variables
 ================================ */
// small modal


var modalSmall = document.getElementById('neutron-modal'); // large modal

var modalLarge = document.getElementById('neutron-modal-large'); // background overlay

var overlay = document.getElementById('overlay');
/* ============================== 
    Small Modal
 ================================ */
// onclick handlers

var showSmall = function showSmall() {
  modalSmall.style.display = 'block';
  overlay.style.display = 'block';
};

var hideSmall = function hideSmall() {
  modalSmall.style.display = 'none';
  overlay.style.display = 'none';
};
/* ===============================
    Large Modal
================================== */
// onclick handlers


var showLarge = function showLarge() {
  modalLarge.style.display = 'block';
  overlay.style.display = 'block';
};

var hideLarge = function hideLarge() {
  modalLarge.style.display = 'none';
  overlay.style.display = 'none';
};
/* ==========================================================================
   Navigation - Mobile
   ========================================================================== */

/**
 *
 * IMPORTANT:
 *
 *
 * TODO:
 */
// Switches the Menu Icon onclick


function mobileMenuIconSwitch() {
  var menuIcon = document.getElementById('neutron-mobile-nav__icon-switch');

  if (menuIcon.innerHTML === 'menu') {
    menuIcon.innerHTML = 'close';
    openMobileNav();
  } else {
    menuIcon.innerHTML = 'menu';
    closeMobileNav();
  }
}

function openMobileNav() {
  document.querySelector('.neutron-mobile-nav').style.width = '100%';
}

function closeMobileNav() {
  document.querySelector('.neutron-mobile-nav').style.width = '0';
}

var mobNav = document.querySelector('.neutron-mobile-nav');
var mobNavParents = [];
var mobNavMenus = [];

if (mobNav) {
  mobNav.addEventListener('click', function (event) {
    if (typeof event.target.dataset.childMenu !== 'undefined') {
      var childMenu = getChildMenu(event.target);
      showMenu(childMenu);
      event.preventDefault();
    }

    if (event.target.classList.contains('neutron-nav-mobile__button_back')) {
      resetMenu();
    }
  });
}

function getChildMenu(element) {
  var selector = element.dataset.childMenu;
  var childMenu = document.querySelector(selector);
  return childMenu;
}

function showMenu(menu) {
  mobNavParents.push(menu.parentElement);
  mobNavMenus.push(menu);
  menu.remove();
  menu.style.transform = 'translateX(100%)';
  mobNav.appendChild(menu);
  setTimeout(reveal, 0);
}

function reveal() {
  var menu = mobNavMenus[mobNavMenus.length - 1];
  var prev = menu.previousElementSibling;
  menu.style.transform = 'translateX(0)';
  prev.style.transform = 'translateX(-100%)';
}

function resetMenu() {
  if (mobNavParents.length === 0 || mobNavMenus.length === 0) {
    return;
  }

  var menu = mobNavMenus[mobNavMenus.length - 1];
  var prev = menu.previousElementSibling;
  menu.style.transform = 'translateX(100%)';
  prev.style.transform = 'translateX(0)';
  mobNav.addEventListener('transitionend', hide, {
    once: true
  });
}

function hide() {
  var menu = mobNavMenus.pop();
  var parent = mobNavParents.pop();
  menu.remove();
  parent.appendChild(menu);
}
/* ================================================================================
   POLYFILLS JS
   ================================================================================ */

/* ==============================
   NodeList FOREACH
   ============================== */


if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
/* ==============================
   Element CLOSEST
   ============================== */


if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = _this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);

    return null;
  };
}
/* eslint-disable no-tabs */

/* ==========================================================================
   Neutron Radio
   ========================================================================== */

/**
 * Basic States: Active, Success, Warning, Error, & Disabled
 *
 * IMPORTANT:
 *
 * TODO:
 */

/* ==========================================================================
   Radio Constructor
   ========================================================================== */


var NeutronRadioButton = function NeutronRadioButton(domNode, groupObj) {
  this.domNode = domNode;
  this.radioGroup = groupObj;
  this.keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
  });
};

NeutronRadioButton.prototype.init = function () {
  this.domNode.tabIndex = -1;
  this.domNode.setAttribute('aria-checked', 'false');
  this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
  this.domNode.addEventListener('click', this.handleClick.bind(this));
  this.domNode.addEventListener('focus', this.handleFocus.bind(this));
  this.domNode.addEventListener('blur', this.handleBlur.bind(this));
};
/* EVENT HANDLERS */


NeutronRadioButton.prototype.handleKeydown = function (e) {
  var flag = false; // tgt = e.currentTarget,
  // clickEvent;

  switch (e.keyCode) {
    case this.keyCode.SPACE:
    case this.keyCode.RETURN:
      this.radioGroup.setChecked(this);
      flag = true;
      break;

    case this.keyCode.UP:
      this.radioGroup.setCheckedToPreviousItem(this);
      flag = true;
      break;

    case this.keyCode.DOWN:
      this.radioGroup.setCheckedToNextItem(this);
      flag = true;
      break;

    case this.keyCode.LEFT:
      this.radioGroup.setCheckedToPreviousItem(this);
      flag = true;
      break;

    case this.keyCode.RIGHT:
      this.radioGroup.setCheckedToNextItem(this);
      flag = true;
      break;

    default:
      break;
  }

  if (flag) {
    e.stopPropagation();
    e.preventDefault();
  }
};

NeutronRadioButton.prototype.handleClick = function () {
  this.radioGroup.setChecked(this);
};

NeutronRadioButton.prototype.handleFocus = function (e) {// this.domNode.classList.add('neutron-radio--is-checked');
};

NeutronRadioButton.prototype.handleBlur = function (e) {// this.domNode.classList.remove('neutron-radio--is-checked');
};
/* ==========================================================================
	 Radiogroup Constructor
   ========================================================================== */


var NeutronRadioGroup = function NeutronRadioGroup(domNode) {
  this.domNode = domNode;
  this.radioButtons = [];
  this.firstNeutronRadio = null;
  this.lastNeutronRadio = null;
};

NeutronRadioGroup.prototype.init = function () {
  var _this2 = this;

  // ensures radiogroup role is added
  if (!this.domNode.getAttribute('role')) {
    this.domNode.setAttribute('role', 'radiogroup');
  }

  var radios = this.domNode.querySelectorAll('.neutron-radio');
  radios.forEach(function (r) {
    var radio = new NeutronRadioButton(r, _this2);
    radio.init();

    _this2.radioButtons.push(radio);

    !_this2.firstNeutronRadio ? _this2.firstNeutronRadio = radio : _this2.lastNeutronRadio = radio;
  });
  this.firstNeutronRadio.domNode.querySelector('.neutron-radio__button').tabIndex = 0;
};

NeutronRadioGroup.prototype.setChecked = function (currentItem) {
  var radio = currentItem.domNode.querySelector('.neutron-radio__button');
  this.radioButtons.forEach(function (b) {
    var button = b.domNode.querySelector('.neutron-radio__button');
    button.setAttribute('aria-checked', 'false');
    button.classList.remove('neutron-radio--is-checked');
    button.tabIndex = -1;
  });
  radio.setAttribute('aria-checked', 'true');
  radio.classList.add('neutron-radio--is-checked');
  radio.tabIndex = 0;
  radio.focus();
};

NeutronRadioGroup.prototype.setCheckedToPreviousItem = function (currentItem) {
  var index;

  if (currentItem === this.firstNeutronRadio) {
    this.setChecked(this.lastNeutronRadio);
  } else {
    index = this.radioButtons.indexOf(currentItem);
    this.setChecked(this.radioButtons[index - 1]);
  }
};

NeutronRadioGroup.prototype.setCheckedToNextItem = function (currentItem) {
  var index;

  if (currentItem === this.lastNeutronRadio) {
    this.setChecked(this.firstNeutronRadio);
  } else {
    index = this.radioButtons.indexOf(currentItem);
    this.setChecked(this.radioButtons[index + 1]);
  }
};
/* Component Variables */


var neutronRadios = document.querySelectorAll('.neutron-radiogroup');
neutronRadios.forEach(function (r) {
  var rg = new NeutronRadioGroup(r);
  rg.init();
});
/* ==========================================================================
   Neutron Sidenav - Tabbed
   ========================================================================== */

/**
 *
 * IMPORTANT:
 *
 *
 * TODO:
 */

/* ==========================================================================
   Global Variables
   ========================================================================== */

function switchTab(event, tabList) {
  var i, tabContent, tabs; // variables
  // Hide tab content

  tabContent = document.getElementsByClassName('neutron-sidenav--tabbed__list');

  for (i = 0; i < tabContent.length; i++) {
    tabContent[i].style.display = 'none';
  } // Remove tab "active" class


  tabs = document.getElementsByClassName('neutron-tablist__tab');

  for (i = 0; i < tabs.length; i++) {
    tabs[i].className = tabs[i].className.replace(' neutron-tablist__tab-on-light--is-active', '');
  } // Show current tab content and assign "active"


  document.getElementById(tabList).style.display = 'block';
  event.currentTarget.className += ' neutron-tablist__tab-on-light--is-active';
}
/* ==========================================================================
   Neutron Tooltip
   ========================================================================== */

/**
 * Short description using Doxygen-style comment format
 *
 * IMPORTANT:
 *
 * TODO:
 */
// global object and initial properties


var tip = {
  tooltip: null,
  parent: null,
  timer: null
};
/* initialisation function */

tip.init = function () {
  // if the necessary collection is supported
  if (typeof document.getElementsByTagName !== 'undefined') {
    // get all tags
    tip.tags = document.querySelectorAll('.neutron-tooltip');
    Object.keys(tip.tags).map(function (t) {
      tip.tags[t].onfocus = tip.focusTimer;
      tip.tags[t].onmouseover = tip.focusTimer;
      tip.tags[t].onmouseout = tip.blurTip;
      tip.tags[t].onblur = tip.blurTip;
    });
  }
};
/* setup initialisation function */


if (typeof window.addEventListener !== 'undefined') {
  // .. gecko, safari, konqueror and standard
  window.addEventListener('load', tip.init, false);
} else if (typeof document.addEventListener !== 'undefined') {
  // .. opera 7
  document.addEventListener('load', tip.init, false);
} else if (typeof window.attachEvent !== 'undefined') {
  // .. win/ie
  window.attachEvent('onload', tip.init);
}
/* find object position */


tip.getRealPosition = function (ele, dir) {
  tip.pos = dir === 'x' ? ele.offsetLeft : ele.offsetTop;
  tip.tmp = ele.offsetParent;

  while (tip.tmp != null) {
    tip.pos += dir === 'x' ? tip.tmp.offsetLeft : tip.tmp.offsetTop;
    tip.tmp = tip.tmp.offsetParent;
  }

  return tip.pos;
};
/* delay timer */


tip.focusTimer = function (e) {
  if (tip.timer != null) {
    clearInterval(tip.timer); // clear timer

    tip.timer = null;
    /* if the current element is not the parent tooltip finds parent with class 'neutron-tooltip' and assign to 'e' */

    if (!e.classList.contains('neutron-tooltip')) {
      e = findParentWithClass(e, 'neutron-tooltip');
    }

    tip.focusTip(e);
  } else {
    tip.tmp = e ? e.target : e.srcElement; // get focused object to pass back through timer

    tip.timer = setInterval(function () {
      return tip.focusTimer(tip.tmp);
    }, 250); // set interval
  }
};
/* create tooltip */


tip.focusTip = function (obj) {
  tip.blurTip(); // remove any existing tooltip
  // if tooltip is null

  if (tip.tooltip == null) {
    // get window dimensions
    if (typeof window.innerWidth !== 'undefined') {
      tip.window = {
        x: window.innerWidth,
        y: window.innerHeight
      };
    } else if (typeof document.documentElement.offsetWidth !== 'undefined') {
      tip.window = {
        x: document.documentElement.offsetWidth,
        y: document.documentElement.offsetHeight
      };
    } else {
      tip.window = {
        x: document.body.offsetWidth,
        y: document.body.offsetHeight
      };
    }
    /* create toolTip, detecting support for namespaced element creation, in case we're in the XML DOM */


    tip.tooltip = typeof document.createElementNS !== 'undefined' ? document.createElementNS('http://www.w3.org/1999/xhtml', 'div') : document.createElement('div');
    var randomIdentifier = Math.floor(Math.random() * 10000);
    /* add classname and unique id */

    tip.tooltip.setAttribute('id', "tooltip".concat(randomIdentifier));
    tip.tooltip.setAttribute('class', 'neutron-tooltip--active');
    obj.setAttribute('aria-describedby', "tooltip".concat(randomIdentifier));
    /* get focused object co-ordinates */

    if (tip.parent == null) {
      tip.parent = {
        x: tip.getRealPosition(obj, 'x'),
        y: tip.getRealPosition(obj, 'y') + 10
      };
    }
    /* offset tooltip from object */


    tip.parent.y += obj.offsetHeight; // apply tooltip position

    tip.tooltip.style.left = tip.parent.x + 'px';
    tip.tooltip.style.top = tip.parent.y + 'px';
    /* write in title attribute */

    var tipText = obj.getAttribute('tooltip-data');
    tip.tooltip.appendChild(document.createTextNode(tipText));
    /* add to document */

    document.getElementsByTagName('body')[0].appendChild(tip.tooltip);
    /* restrict width */

    if (tip.tooltip.offsetWidth > 200) {
      tip.tooltip.style.width = '200px';
    }
    /* get tooltip tip.extent */


    tip.extent = {
      x: tip.tooltip.offsetWidth,
      y: tip.tooltip.offsetHeight
    };
    /* if tooltip exceeds window width */

    if (tip.parent.x + tip.extent.x >= tip.window.x) {
      // shift tooltip left
      tip.parent.x -= tip.extent.x;
      tip.tooltip.style.left = tip.parent.x + 'px';
    }
    /* get scroll height */


    if (typeof window.pageYOffset !== 'undefined') {
      tip.scroll = window.pageYOffset;
    } else if (typeof document.documentElement.scrollTop !== 'undefined') {
      tip.scroll = document.documentElement.scrollTop;
    } else {
      tip.scroll = document.body.scrollTop;
    }
    /* if tooltip exceeds window height */


    if (tip.parent.y + tip.extent.y >= tip.window.y + tip.scroll) {
      // shift tooltip up
      tip.parent.y -= tip.extent.y + obj.offsetHeight + 4;
      tip.tooltip.style.top = tip.parent.y + 'px';
    }
    /* centers tooltip below subject */


    var width = (tip.tooltip.offsetWidth - 24) / 2;
    tip.tooltip.style.marginLeft = "-".concat(width, "px");
    /* Creates transition animation */

    setTimeout(function () {
      if (tip.tooltip == null) {} else {
        tip.tooltip.style.opacity = 1;
      }
    }, 400);
  }
}; // remove tooltip


tip.blurTip = function (e) {
  // if tooltip exists
  if (tip.tooltip != null) {
    // remove and nullify tooltip
    document.getElementsByTagName('body')[0].removeChild(tip.tooltip);
    e.currentTarget.setAttribute('aria-describedby', '');
    tip.tooltip = null;
    tip.parent = null;
  } // cancel timer


  clearInterval(tip.timer);
  tip.timer = null;
};
/* ================================================================================
   UTILS JS
   ================================================================================ */
// traverses dom nodes until it finds parent that has the desired class


function findParentWithClass(el, cls) {
  while (el.parentNode) {
    el = el.parentNode;

    if (el.classList.contains(cls)) {
      return el;
    }
  }

  return null;
}