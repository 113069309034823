import { Reducer } from 'react';

import { State } from '../constants/models';
import { Action } from './globalStore';

export const globalReducer: Reducer<State, Action> = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_APPLIST':
      return { ...state, appList: payload };
    case 'SET_GLOBAL_ERROR':
      return { ...state, globalError: payload };
    case 'SET_ITEM_ERROR':
      return { ...state, itemError: payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: payload };
    case 'SET_IS_UPDATING':
      return { ...state, isUpdating: payload };
    case 'SET_SELECTED_APP':
      return { ...state, selectedApp: payload };
    default:
      return state;
  }
};
