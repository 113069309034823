import { AppObject } from '../constants/models';

export enum ActionTypes {
  SET_APPLIST = 'SET_APPLIST',
  SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR',
  SET_ITEM_ERROR = 'SET_ITEM_ERROR',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_UPDATING = 'SET_IS_UPDATING',
  SET_SELECTED_APP = 'SET_SELECTED_APP'
}

export const setAppListAction = (appList: AppObject[] | []) => ({
  type: ActionTypes.SET_APPLIST,
  payload: appList
});

export const setGlobalErrorAction = (globalError: string | undefined) => ({
  type: ActionTypes.SET_GLOBAL_ERROR,
  payload: globalError
});

export const setItemErrorAction = (itemError: string | undefined) => ({
  type: ActionTypes.SET_ITEM_ERROR,
  payload: itemError
});

export const setIsLoadingAction = (isLoading: boolean) => ({
  type: ActionTypes.SET_IS_LOADING,
  payload: isLoading
});

export const setIsUpdatingAction = (isUpdating: boolean) => ({
  type: ActionTypes.SET_IS_UPDATING,
  payload: isUpdating
});

export const setSelectedAppAction = (selectedApp: AppObject) => ({
  type: ActionTypes.SET_SELECTED_APP,
  payload: selectedApp
});
