/* eslint-disable @typescript-eslint/camelcase */
import { UserManagerSettings } from './Interfaces';

const hostName: string = window.location.hostname;
const prodAuthority = 'https://pf.idf.medcity.net';
const devAndTestAuthority = 'https://pfqa.idf.medcity.net';
const clientId = 'HcaCustomAppStore';
const redirectUri = `https://${window.location.host}/redirect`;

// runs evaluation on environment to set ping config
const openIdConnectUrl =
  hostName.includes('dev') ||
  hostName.includes('-qa') ||
  hostName === 'localhost'
    ? devAndTestAuthority
    : prodAuthority;

const config: UserManagerSettings = {
  authority: openIdConnectUrl,
  client_id: clientId,
  redirect_uri: redirectUri,
  silent_redirect_uri: redirectUri,
  post_logout_redirect_uri: `https://${window.location.host}/`,
  scope:
    hostName === 'localhost' ||
    hostName.includes('dev') ||
    hostName.includes('-qa')
      ? 'mfa'
      : 'mobilecatalog',
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: false,
  triggerAuthFlow: true
};

export default config;
