import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import '../../styles/ErrNotFound.scss';

type ErrNotFoundProps = {
  message?: string;
  statusCode: number | string;
  title?: string;
};

const ErrNotFound: React.FC<ErrNotFoundProps> = (props: ErrNotFoundProps) => {
  const [statusCode] = useState(props.statusCode ? props.statusCode : 'Error');
  const [title, setTitle] = useState(props.title);
  const [message, setMessage] = useState(props.message);

  // #endregion Hooks
  useEffect(() => {
    switch (statusCode) {
      case 401:
        setTitle('Not Authenticated');
        setMessage(
          'The API Request could not be authenticated. The ' +
            'Authorization was not sent or was not properly formed as a ' +
            'Bearer token.'
        );
        break;
      case 403:
        setTitle('Unauthorized');
        setMessage(
          'This user is not properly authorized to use this application.'
        );
        break;
      case 404:
        setTitle('Page Not Found');
        setMessage("Uh Oh. It looks like you're lost.");
        break;
      case 500:
        setTitle('Session Lost');
        setMessage('To reinstate a session, relocate back to the home page.');
        break;
      case 'iOS':
        setTitle('Devices Only');
        setMessage(
          'Currently, the application you are trying to use can only ' +
            'be accessed on an iOS device, such as: iPad, iPad Pro, iPhone, ' +
            'or iPod Touch.'
        );
        break;
      case 'Error':
        setTitle('Unknown');
        setMessage(
          'An Unknown Error occurred, the Network connection may have been lost, or the Session may have been lost. Please check your connection or contact an administrator.'
        );
        break;

      default:
        break;
    }
  }, [statusCode]);

  return (
    <div className="page-container err-not-found container">
      <div className="center-content__col--top-spacing neutron-text--align-center row">
        <div className="col-12">
          <h2 className="err-not-found__header">{statusCode}</h2>
          <h2 className="neutron-text--bold">{title}</h2>
          <p className="neutron-text--body">{message}</p>
          {statusCode !== 'iOS' && (
            <Link
              to="/"
              className="neutron-button--primary
										neutron-button
										neutron-button--raised"
              role="button"
              arial-label="Go Home Button"
              tabIndex={0}
            >
              Go Home
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrNotFound;
