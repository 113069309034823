import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  AuthenticationProvider,
  InMemoryWebStorage
} from '@axa-fr/react-oidc-context';

import oidcConfiguration from './auth/config';
import RedirectPage from './auth/RedirectPage';
import AuthRoute from './auth/AuthRoute';
import '../styles/App.scss';
import '../neutron/icons/material-icons/material-icons.css';
import '../neutron/css/neutron.css';
import Loading from './shared/Loading';
import Navigation from './main/Navigation';
import GlobalStateProvider from '../state/globalStore';
import AuthLoading from './auth/AuthLoading';
import SessionLost from './auth/SessionLost';

const ErrNotFound = lazy(() => import('./main/ErrNotFound'));
const AppDetails = lazy(() => import('./apps/AppDetails'));
const AppList = lazy(() => import('./apps/AppList'));

const initializeNeutronScript = (callback: () => void) => {
  const existingScript = document.getElementById('neutron-script');

  if (!existingScript) {
    const script = document.createElement('script');
    script.type = 'text/jsx';
    script.src = require('../neutron/js/neutron.js');
    script.id = 'neutron-script';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const isIOSDevice = () => {
  return (
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream
  );
};

const App: React.FC = () => {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    if (
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('dev')
    ) {
      setIsIOS(true);
    } else {
      setIsIOS(isIOSDevice());
    }

    initializeNeutronScript(() => {});
  }, []);

  return (
    <GlobalStateProvider>
      <ToastContainer
        autoClose={false}
        draggable={true}
        draggablePercent={60}
        enableMultiContainer={true}
      />
      <Router>
        <Navigation />
        <Suspense fallback={<Loading />}>
          {isIOS && (
            <AuthenticationProvider
              configuration={oidcConfiguration}
              isEnabled={true}
              notAuthenticated={ErrNotFound}
              notAuthorized={ErrNotFound}
              authenticating={AuthLoading}
              sessionLostComponent={SessionLost}
              callbackComponentOverride={AuthLoading}
              UserStore={InMemoryWebStorage}
            >
              <Switch>
                <AuthRoute exact path="/" component={AppList} />
                <AuthRoute path="/apps/:id" component={AppDetails} />
                <Route exact path="/redirect">
                  <RedirectPage />
                </Route>
                <Route>
                  <ErrNotFound statusCode={404} />
                </Route>
              </Switch>
            </AuthenticationProvider>
          )}
          {!isIOS && (
            <Route>
              <ErrNotFound statusCode="iOS" />
            </Route>
          )}
        </Suspense>
      </Router>
    </GlobalStateProvider>
  );
};

export default App;
