import React, { useEffect, useState } from 'react';

const AuthLoading: React.FC = (): JSX.Element => {
  const [checkConnection, setCheckConnection] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCheckConnection(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="container center-content__col--top-spacing neutron-text--align-center"
      role="status"
    >
      <div className={'neutron-margin--bottom-30 neutron-text--align-center'}>
        <span>
          <h2 className="neutron-text--bold" style={{ lineHeight: '1rem' }}>
            Mobile Catalog
          </h2>
        </span>
      </div>
      <h6>Authenticating...</h6>
      {checkConnection && (
        <h6 className="neutron-text--american-rose">
          Check Your Network Connection
        </h6>
      )}
      <svg
        className={`neutron-feedback--spinner neutron-margin--top-20`}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="neutron-feedback--spinner__fill"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  );
};

export default AuthLoading;
