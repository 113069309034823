import React, { createContext, useReducer } from 'react';

import { PropsWithChildren, State } from '../constants/models';
import { ActionTypes } from './globalActions';
import { globalReducer } from './globalReducer';

export const initialState: State = {
  appList: [],
  globalError: '',
  itemError: '',
  isLoading: false,
  isUpdating: false,
  selectedApp: null
};

export interface Action {
  type: ActionTypes;
  payload?: any;
}

export const GlobalStateContext = createContext<{
  state: State;
  dispatch: (action: Action) => void;
}>({ state: initialState, dispatch: () => {} });

const GlobalStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [globalState, dispatchToGlobal] = useReducer(
    globalReducer,
    initialState
  );

  return (
    <GlobalStateContext.Provider
      value={{ state: globalState, dispatch: dispatchToGlobal }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
